/*
  Thpace = Three + Space;
*/
var Thpace = /** @class */ (function () {
    function Thpace(canvas, settings) {
        if (settings === void 0) { settings = null; }
        if (!canvas) {
            console.log('Need a valid canvas element.');
            return;
        }
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d');
        this.triangles = [];
        this.coordinateTable = {};
        this.baseCoordinateTable = {};
        this.settings = extend({
            triangleSize: 130,
            bleed: 200,
            noise: 60,
            //color1: '#360033',
            //color2: '#0b8793',
            color1: '#ffffff',
            color2: '#6fb4b9',
            pointVariationX: 20,
            pointVariationY: 35,
            pointAnimationSpeed: 15,
            image: false,
            imageOpacity: .4
        });
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
        this.gradientOpacity = 1;
    }
    Thpace.prototype.animate = function () {
        var _this = this;
        var ctx = this.ctx;
        ctx.clearRect(0, 0, this.width, this.height);
        this.triangles.forEach(function (t) {
            ctx.beginPath();
            var coords = [];
            coords.push({ x: t[0][0], y: t[0][1] });
            coords.push({ x: t[1][0], y: t[1][1] });
            coords.push({ x: t[2][0], y: t[2][1] });
            var color = t[3];
            ctx.fillStyle = color;
            ctx.strokeStyle = color;
            var dp = [0, 1, 2, 0];
            dp.forEach(function (el, ind) {
                if (_this.coordinateTable[coords[el].x] && _this.coordinateTable[coords[el].x][coords[el].y] != undefined) {
                    var c = { x: coords[el].x, y: coords[el].y };
                    var change = _this.coordinateTable[coords[el].x][coords[el].y];
                    if (ind == 0) {
                        ctx.moveTo(c.x + change.x, c.y + change.y);
                    }
                    else {
                        ctx.lineTo(c.x + change.x, c.y + change.y);
                    }
                }
            });
            ctx.fill();
            ctx.stroke();
        });
        if (this.settings.image) {
            var pat = ctx.createPattern(this.settings.image, 'repeat');
            ctx.globalAlpha = this.settings.imageOpacity;
            ctx.fillStyle = pat;
            ctx.fillRect(0, 0, this.width, this.height);
            ctx.globalAlpha = 1;
        }
        this.animateCoordinateTable();
        requestAnimationFrame(this.animate.bind(this));
    };
    Thpace.prototype.start = function () {
        this.animate();
    };
    Thpace.prototype.generateTriangles = function () {
        var _this = this;
        var points = [];
        var coordinateTable = {};
        points.push([0, 0]);
        points.push([0, this.height]);
        points.push([this.width, 0]);
        points.push([this.width, this.height]);
        var bleed = this.settings.bleed;
        var size = this.settings.triangleSize;
        var noise = this.settings.noise;
        for (var i = 0 - bleed; i < this.width + bleed; i += size) {
            for (var j = 0 - bleed; j < this.height + bleed; j += size) {
                var x = i + getRandomInt(0, noise);
                var y = j + getRandomInt(0, noise);
                points.push([x, y]);
            }
        }
        var delaunay = Delaunator.from(points);
        var triangleList = delaunay.triangles;
        var coordinates = [];
        for (var i_1 = 0; i_1 < triangleList.length; i_1 += 3) {
            var t = [
                points[triangleList[i_1]],
                points[triangleList[i_1 + 1]],
                points[triangleList[i_1 + 2]],
            ];
            var coords = [];
            coords.push({ x: t[0][0], y: t[0][1] });
            coords.push({ x: t[1][0], y: t[1][1] });
            coords.push({ x: t[2][0], y: t[2][1] });
            var color = gradient(getCenter(coords), this.width, this.height, this.settings.color1, this.settings.color2);
            t.push(color);
            for (var j = 0; j <= 2; j++) {
                var p = points[triangleList[i_1 + j]];
                var x = p[0];
                var y = p[1];
            }
            coordinates.push(t);
        }
        var baseCoordinateTable = {};
        coordinates.forEach(function (t) {
            t.forEach(function (p) {
                var x = p[0];
                var y = p[1];
                if (!coordinateTable[x]) {
                    coordinateTable[x] = {};
                }
                var per = x / _this.width;
                coordinateTable[x][y] = 0;
                if (!baseCoordinateTable[x]) {
                    baseCoordinateTable[x] = {};
                }
                baseCoordinateTable[x][y] = per * 2 * Math.PI;
            });
        });
        this.triangles = coordinates;
        this.coordinateTable = coordinateTable;
        this.baseCoordinateTable = baseCoordinateTable;
    };
    Thpace.prototype.animateCoordinateTable = function () {
        var _this = this;
        Object.keys(this.coordinateTable).forEach(function (x) {
            Object.keys(_this.coordinateTable[x]).forEach(function (y) {
                _this.baseCoordinateTable[x][y] += _this.settings.pointAnimationSpeed / 1000;
                var changeX = (Math.cos(_this.baseCoordinateTable[x][y]) * _this.settings.pointVariationX);
                var changeY = (Math.sin(_this.baseCoordinateTable[x][y]) * _this.settings.pointVariationY);
                _this.coordinateTable[x][y] = {
                    x: changeX,
                    y: changeY
                };
            });
        });
    };
    Thpace.prototype.resize = function () {
        var p = this.canvas.parentElement;
        this.canvas.width = p.clientWidth;
        this.canvas.height = p.clientHeight;
        this.width = p.clientWidth;
        this.height = p.clientHeight;
        this.generateTriangles();
        //this.generateParticles();
    };
    return Thpace;
}());
function gradient(coords, width, height, leftColor, rightColor) {
    var x = coords.x;
    var y = coords.y;
    var per = 0;
    per = (x / width);
    var per2 = 0;
    per2 = (y / height);
    per = (per2 + per) / 2;
    if (per > 1) {
        per = 1;
    }
    else if (per < 0) {
        per = 0;
    }
    var color1 = leftColor;
    var color2 = rightColor;
    var hex = function (x) {
        x = x.toString(16);
        return (x.length == 1) ? '0' + x : x;
    };
    var r = Math.ceil(parseInt(color1.substring(1, 3), 16) * per + parseInt(color2.substring(1, 3), 16) * (1 - per));
    var g = Math.ceil(parseInt(color1.substring(3, 5), 16) * per + parseInt(color2.substring(3, 5), 16) * (1 - per));
    var b = Math.ceil(parseInt(color1.substring(5, 7), 16) * per + parseInt(color2.substring(5, 7), 16) * (1 - per));
    var middle = "#" + hex(r) + hex(g) + hex(b);
    // if (up) {
    //     offset += 6;
    // }
    // if (offset > width * 2) {
    //     offset = 0;
    // }
    // if (debug) {
    //     //console.log(per);
    // }
    return middle;
}
function getCenter(coords) {
    var sumX = 0;
    var sumY = 0;
    coords.forEach(function (p) {
        sumX += p.x;
        sumY += p.y;
    });
    return { x: sumX / coords.length, y: sumY / coords.length };
}
var extend = function (out) {
    out = out || {};
    for (var i = 1; i < arguments.length; i++) {
        if (!arguments[i])
            continue;
        for (var key in arguments[i]) {
            if (arguments[i].hasOwnProperty(key))
                out[key] = arguments[i][key];
        }
    }
    return out;
};
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
(function () {
    document.addEventListener("DOMContentLoaded", function (event) {
    });
    window.onload = function () {
        var canvas = document.getElementById('effect_canvas');
        var spaceboi = new Thpace(canvas);
        spaceboi.start();
    };
})();
